/**
 *
 * Utility to load the scripts (internal / external) within the component
 */
const loadScript = ({ src, nonce = '' }) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    if (nonce) {
      script.setAttribute('data-csp-nonce', nonce)
    }
    script.defer = true
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.body.appendChild(script)
  })

export { loadScript }
