// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banner-js": () => import("./../../../src/pages/banner.js" /* webpackChunkName: "component---src-pages-banner-js" */),
  "component---src-pages-build-better-js": () => import("./../../../src/pages/build-better.js" /* webpackChunkName: "component---src-pages-build-better-js" */),
  "component---src-pages-classic-home-js": () => import("./../../../src/pages/classic-home.js" /* webpackChunkName: "component---src-pages-classic-home-js" */),
  "component---src-pages-docs-template-js": () => import("./../../../src/pages/docsTemplate.js" /* webpackChunkName: "component---src-pages-docs-template-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-builder-index-tsx": () => import("./../../../src/pages/integration-builder/index.tsx" /* webpackChunkName: "component---src-pages-integration-builder-index-tsx" */),
  "component---src-pages-integration-builder-sections-build-tsx": () => import("./../../../src/pages/integration-builder/sections/Build.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-build-tsx" */),
  "component---src-pages-integration-builder-sections-card-generator-tsx": () => import("./../../../src/pages/integration-builder/sections/CardGenerator.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-card-generator-tsx" */),
  "component---src-pages-integration-builder-sections-code-tsx": () => import("./../../../src/pages/integration-builder/sections/Code.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-code-tsx" */),
  "component---src-pages-integration-builder-sections-configure-tsx": () => import("./../../../src/pages/integration-builder/sections/Configure.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-configure-tsx" */),
  "component---src-pages-integration-builder-sections-demo-tsx": () => import("./../../../src/pages/integration-builder/sections/Demo.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-demo-tsx" */),
  "component---src-pages-integration-builder-sections-help-modal-tsx": () => import("./../../../src/pages/integration-builder/sections/HelpModal.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-help-modal-tsx" */),
  "component---src-pages-integration-builder-sections-know-before-you-code-tsx": () => import("./../../../src/pages/integration-builder/sections/KnowBeforeYouCode.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-know-before-you-code-tsx" */),
  "component---src-pages-integration-builder-sections-preview-tsx": () => import("./../../../src/pages/integration-builder/sections/Preview.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-preview-tsx" */),
  "component---src-pages-integration-builder-sections-take-live-tsx": () => import("./../../../src/pages/integration-builder/sections/TakeLive.tsx" /* webpackChunkName: "component---src-pages-integration-builder-sections-take-live-tsx" */),
  "component---src-pages-internal-js": () => import("./../../../src/pages/internal.js" /* webpackChunkName: "component---src-pages-internal-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-signupauto-js": () => import("./../../../src/pages/signupauto.js" /* webpackChunkName: "component---src-pages-signupauto-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-video-collection-tsx": () => import("./../../../src/pages/video/collection.tsx" /* webpackChunkName: "component---src-pages-video-collection-tsx" */),
  "component---src-pages-video-home-tsx": () => import("./../../../src/pages/video/home.tsx" /* webpackChunkName: "component---src-pages-video-home-tsx" */),
  "component---src-pages-video-watch-tsx": () => import("./../../../src/pages/video/watch.tsx" /* webpackChunkName: "component---src-pages-video-watch-tsx" */),
  "component---src-templates-template-layout-js": () => import("./../../../src/templates/TemplateLayout.js" /* webpackChunkName: "component---src-templates-template-layout-js" */)
}

