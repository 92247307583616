export const config = {
  docsBannerInfo: {
    enabled: false,
    message: 'Available in the US, Canada, UK, and Italy',
  },
  docsMobileCheckoutBannerInfo: {
    enabled: true,
    message:
      'This integration path is only active for existing Apps using a previous beta version. For any new Apps seeking the Native Checkout experience, please integrate via the <a href="/beta/advanced-mobile/">PayPal Mobile SDK</a> or <a href="https://developer.paypal.com/braintree/docs/guides/paypal/native-checkout/javascript/v3/">Braintree SDK</a>',
  },
  googleAnalytics: {
    appName: 'devdiscoverynodeweb',
    stock: 'UA-37159521-1',
    exp: 'UA-37159521-8',
  },
}
