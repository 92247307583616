import Provider from './src/provider'

export const onRouteUpdate = (obj) => {
  let hashId = document.getElementById('hashLinkContainer')
  if (hashId) {
    hashId.setAttribute('hashlink', obj.location.pathname)
  }
}

export const wrapRootElement = Provider
