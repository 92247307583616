module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"@paypalcorp/gatsby-remark-partial","options":{"directory":"/home/jenkins/agent/workspace/PP Devdocs Release/release/src/"}},{"resolve":"/home/jenkins/agent/workspace/PP Devdocs Release/release/plugins/gatsby-remark-fix-links/index.js"},{"resolve":"/home/jenkins/agent/workspace/PP Devdocs Release/release/plugins/gatsby-remark-modify-headers/index.js"}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/jenkins/agent/workspace/PP Devdocs Release/release","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(demo\\/checkout)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://developer.paypal.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
