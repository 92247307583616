import { useState, useEffect } from 'react'

export default function useApi(endpoint) {
  const [data, setData] = useState({})
  useEffect(() => {
    fetch(endpoint)
      .then((res) => res.json())
      .then((dataRes) => {
        setData(dataRes)
        return
      })
      .catch(() => {
        setData({
          success: false,
        })
      })
  }, [endpoint])
  return data
}
